import $ from "jquery";
import Swiper from 'swiper';

const carousel = new Swiper('#js-carousel .swiper-container', {
  autoplay: {
    delay: 2000,
  },
  loop: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  slidesPerView: 1,
  spaceBetween: 0,
  speed: 1500,
});

const whatsNewCarousel = new Swiper('#js-whats-new-carousel .swiper-container', {
  breakpoints: {
    767: {
      centeredSlides: true,
      slidesPerView: 1.36,
    },
  },
  navigation: {
    nextEl: '#js-whats-new-carousel .swiper-button-next',
    prevEl: '#js-whats-new-carousel .swiper-button-prev',
  },
  slidesPerView: 4,
  spaceBetween: 0,
});

$(function() {

// 共通変数宣言
const activeClass = '-isActive',
      bpDown = 767;

let deviceW;

// ハンバーガーメニュー
const gnavTrigger = '#js-gnavTrigger',
      gnav = '#js-gnav'
$(gnavTrigger).on('click', function() {
  $(this).toggleClass(activeClass)
  if($(this).hasClass(activeClass)){
    $(gnav).stop().slideDown();
  } else {
    $(gnav).stop().slideUp();
  }
  return false;
});

// SPグロナビ
const gnavLowerLevelTrigger = '.js-gnavLowerLevelTrigger',
      gnavLowerLevel = '.js-gnavLowerLevel'
$(gnavLowerLevelTrigger).on('click', function(e) {
  deviceW = $(window).width();
  if(deviceW < bpDown) {
    e.preventDefault();
    $(this).toggleClass(activeClass)
    if($(this).hasClass(activeClass)){
      $(this).next(gnavLowerLevel).stop().slideDown();
    } else {
      $(this).next(gnavLowerLevel).stop().slideUp();
    }
    return false;
  }
});

// アコーディオンメニュー
const accordionMenuTrigger = '.js-accordionMenuTrigger',
      accordionMenu = '.js-accordionMenu'
$(accordionMenuTrigger).on('click', function() {
  $(this).toggleClass(activeClass)
  if($(this).hasClass(activeClass)){
    $(this).next(accordionMenu).stop().slideDown();
  } else {
    $(this).next(accordionMenu).stop().slideUp();
  }
  return false;
});

// FAQ
$('.js-q').on('click', function() {
  $(this).toggleClass(activeClass)
  if($(this).hasClass(activeClass)){
    $(this).next('.js-a').stop().slideDown();
  } else {
    $(this).next('.js-a').stop().slideUp();
  }
  return false;
});

});
